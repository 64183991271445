import React, { useState } from "react";
import { Cross1Icon, MagicWandIcon } from "@radix-ui/react-icons";
import { useFormik, FormikErrors } from "formik";
import axios, { AxiosError } from "axios";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

import { Button } from "./Button";
import { Field } from "./Field";
import {
  API_URL,
  DECISIONS_QUERY,
  DECISIONS_TEAM_POST_PATH,
  DecisionOut,
  Team,
  transformCreateDecisionOut,
} from "../types";
import { useParams } from "react-router-dom";
import { UiTitle } from "pages/Onboarding";
import { CONTAINER_HORIZONTAL_PADDING, MAIN_BORDER_COLOR } from "./Variables";

const decisionNameValidation = yup
  .string()
  .required("Required")
  .matches(/^[a-zA-Z0-9_-]+$/, "Only letters, numbers, _, and - are allowed");
const decisionValueValidation = yup.string().required("Required");

export const createDecisionValidationSchema = yup.object({
  decisionName: decisionNameValidation,
  value: decisionValueValidation,
});

export const updateDecisionValidationSchema = yup.object().shape({
  name: decisionNameValidation,
  value: decisionValueValidation,
});

export const createDecisionInitialValues: DecisionOut = {
  decisionName: "",
  value: "",
  comments: [],
  tags: [],
};

export const useCreateDecision = ({
  teamId,
  onSuccessCallback,
}: { teamId?: Team["id"]; onSuccessCallback?: () => void } = {}) => {
  const queryClient = useQueryClient();

  const createDecisionMutation = async (decision: DecisionOut) => {
    if (!teamId) {
      throw new Error("Team ID is required to create a decision");
    }

    const payload = transformCreateDecisionOut({ ...decision, teamId });

    return axios.post(`${API_URL}${DECISIONS_TEAM_POST_PATH}`, payload, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const mutation = useMutation(createDecisionMutation);

  const createDecisionForm = useFormik({
    initialValues: createDecisionInitialValues,
    validationSchema: createDecisionValidationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      mutation.mutate(values, {
        onSuccess: () => {
          toast.success("Created decision!");
          createDecisionForm.resetForm();
          queryClient.invalidateQueries({ queryKey: [DECISIONS_QUERY] });
          onSuccessCallback && onSuccessCallback();
        },
        // @ts-ignore
        onError: (error: AxiosError) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      });
    },
  });

  return { createDecisionForm, mutation };
};

// TODO: REMOVE THIS CODE
const CreateDecision = ({
  teamId,
  handleCancelClick,
}: {
  teamId?: number;
  handleCancelClick?: () => void;
}) => {
  const { createDecisionForm, mutation } = useCreateDecision({
    teamId: teamId,
  });

  return (
    <div
      style={{
        paddingLeft: CONTAINER_HORIZONTAL_PADDING,
        paddingRight: CONTAINER_HORIZONTAL_PADDING,
        boxSizing: "border-box",
        border: `1px solid ${MAIN_BORDER_COLOR}`,
        borderRadius: 6,
      }}
      className="mb-4"
    >
      <div className="container mx-auto py-12" id="create_decision">
        <UiTitle>Create Decision</UiTitle>
        <form onSubmit={createDecisionForm.handleSubmit}>
          <Field
            id="decisionName"
            name="decisionName"
            onChange={createDecisionForm.handleChange}
            value={createDecisionForm.values.decisionName}
            label="Decision Name"
            errors={createDecisionForm.errors}
            helpText="Must be unique and will be used to identify the decision. Only letters, numbers, _, and - are allowed. "
          />
          <Field
            id="value"
            name="value"
            onChange={createDecisionForm.handleChange}
            value={createDecisionForm.values.value}
            label="Decision Value"
            errors={createDecisionForm.errors}
            helpText="This can be any text, a URL, or a numeric value."
          />
          <Button
            type="submit"
            loading={mutation.isLoading}
            icon={<MagicWandIcon />}
          >
            Create decision
          </Button>
          <Button
            type="button"
            icon={<Cross1Icon />}
            onClick={handleCancelClick}
            muted
          >
            Cancel
          </Button>
        </form>
      </div>
    </div>
  );
};
